import React, { useState, useEffect, useRef } from "react";
import Style from "./VideoDetails.module.css";
import "./Courses.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-toastify";

const VideoDetails = () => {
   let {vedioId}= useParams()
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const dropdownRef = useRef(null);
  const [loading, setloading] = useState(false);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const navigate =useNavigate()
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const handleClick = (cousreIndex) => {
    console.log("sss",cousreIndex);
    navigate(`/${vedioId}/UpdateQuiz/${cousreIndex}`)

  };
 
    const [courseData, setCourseData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          setloading(true)
          const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes?videoId=${vedioId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
          });
          setloading(false)
          setCourseData(response?.data.data);
        }    catch (error) {
          if (error.response.status === 405) {
            setloading(false)
            navigate('/login') ;
             notify(error.response.data.message, "success");
      
          }
          setloading(false)
      
          notify(error.response.data.message, "success");
      
          console.error('Error fetching profile data:', error);
        }
      };
    
  
      fetchData();
  
    }, []);
  return (
    <>
      <Helmet>
        <title>الأختبار</title>
      </Helmet>

      {loading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          
      <div className={`${Style.home} bg-gray-100 rounded-xl min-h-screen`}>
      <div className="  flex items-center bg-white p-5 rounded-xl my-5">

<Link to={'/'} className=' text-green-500' >
قائمة الدورات التعليمية
</Link>

   <div className="flex items-end pt-1">
      <span className='px-2'>
          <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
          </svg>
      </span>
  </div>

  <div className="flex items-center">
      <Link className="mr-2 text-green-500">قائمة الفيديوهات</Link>
  </div>   
  
   <div className="flex items-end pt-1">
      <span className='px-2'>
          <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
          </svg>
      </span>
  </div>
<Link className='font-bold text-green-600' >
      الأختبار
</Link>
</div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="Corsess-Head">
              <tr className="flex flex-row  h-15">
                <th className="max-h-14 flage flex w-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="18"
                    viewBox="0 0 20 24"
                    fill="none"
                  >
                    <path
                      d="M0 0L1.50842 0L1.50842 24H0L0 0ZM21 6.75L3.01683 13.4531L3.01683 0.046875L21 6.75ZM4.52525 11.2969L16.7104 6.75L4.52525 2.20313L4.52525 11.2969Z"
                      fill="#009539"
                    />
                  </svg>
                </th>
                <th className=" Address mx-3 p-3 max-h-14 basis-5/6 ">
                  <div className="flex flex-row">
                    <div className="text-right basis-3/3">عنوان الاختبار</div>
                    {/* <div className="text-center basis-3/3  ">نوع الدوره التعليمية</div> */}
                  </div>
                </th>
                <th className="flex-none max-h-14 pt-1 p-2">
                    {courseData?<>
                        <Link to={`/${vedioId}/AddExam`}  type="button" className=" button btn btn-disabled">
                    <span className="">اضافة أختبار</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="14" cy="14" r="14" fill="#FBFBFB" />
                      <path
                        d="M16 15.5H15.5V16V22.5C15.5 22.7652 15.3946 23.0196 15.2071 23.2071C15.0196 23.3946 14.7652 23.5 14.5 23.5C14.2348 23.5 13.9804 23.3946 13.7929 23.2071C13.6054 23.0196 13.5 22.7652 13.5 22.5V16V15.5H13H6.5C6.23478 15.5 5.98043 15.3946 5.79289 15.2071C5.60536 15.0196 5.5 14.7652 5.5 14.5C5.5 14.2348 5.60536 13.9804 5.79289 13.7929C5.98043 13.6054 6.23478 13.5 6.5 13.5H13H13.5V13V6.5C13.5 6.23478 13.6054 5.98043 13.7929 5.79289C13.9804 5.60536 14.2348 5.5 14.5 5.5C14.7652 5.5 15.0196 5.60536 15.2071 5.79289C15.3946 5.98043 15.5 6.23478 15.5 6.5V13V13.5H16H22.5C22.7652 13.5 23.0196 13.6054 23.2071 13.7929C23.3946 13.9804 23.5 14.2348 23.5 14.5C23.5 14.7652 23.3946 15.0196 23.2071 15.2071C23.0196 15.3946 22.7652 15.5 22.5 15.5H16Z"
                        fill="#009539"
                        stroke="#009539"
                      />
                    </svg>
                  </Link>
                    </>:<>
                    <Link to={`/${vedioId}/AddExam`} type="button" className="button btn">
                    <span className="">اضافة أختبار</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="14" cy="14" r="14" fill="#FBFBFB" />
                      <path
                        d="M16 15.5H15.5V16V22.5C15.5 22.7652 15.3946 23.0196 15.2071 23.2071C15.0196 23.3946 14.7652 23.5 14.5 23.5C14.2348 23.5 13.9804 23.3946 13.7929 23.2071C13.6054 23.0196 13.5 22.7652 13.5 22.5V16V15.5H13H6.5C6.23478 15.5 5.98043 15.3946 5.79289 15.2071C5.60536 15.0196 5.5 14.7652 5.5 14.5C5.5 14.2348 5.60536 13.9804 5.79289 13.7929C5.98043 13.6054 6.23478 13.5 6.5 13.5H13H13.5V13V6.5C13.5 6.23478 13.6054 5.98043 13.7929 5.79289C13.9804 5.60536 14.2348 5.5 14.5 5.5C14.7652 5.5 15.0196 5.60536 15.2071 5.79289C15.3946 5.98043 15.5 6.23478 15.5 6.5V13V13.5H16H22.5C22.7652 13.5 23.0196 13.6054 23.2071 13.7929C23.3946 13.9804 23.5 14.2348 23.5 14.5C23.5 14.7652 23.3946 15.0196 23.2071 15.2071C23.0196 15.3946 22.7652 15.5 22.5 15.5H16Z"
                        fill="#009539"
                        stroke="#009539"
                      />
                    </svg>
                  </Link>
                    </>}
                  
                </th>
              </tr>
            </thead>
            <span className={Style.brmedium}></span>
            <span className={Style.brmedium}></span>

            <tbody className="">
              
           {courseData?<>
            {[1].map((index) => (
                <React.Fragment key={index}>
                  <span className={Style.brmedium}></span>


                  <tr className="flex flex-row h-15 ">

                    <th
                      className={`max-h-14 flex w-16 ${
                        courseData?.isFree ? "special-flage" : "flage"
                                            }`}
                    >
                                        <Link to={`/${vedioId}/ExamDetails`} >

                      {index}
                      </Link>
                    </th>
                    <th
                      className={`ms-3 p-3 max-h-14 basis-5/6 ${
                        courseData?.isFree                           ? "special-row"
                          : "bodyCorsess"
                      }`}
                    >
                      <div className="flex flex-row">
                        <div className="text-right basis-1/3 ">
                        <Link  to={`/${vedioId}/ExamDetails`} >
{courseData?.quizTitle}
</Link>
</div>
                        {/* <div className="flex paymentOrFree basis-1/3">
                          
                          {course?.isFree  ? (
                            <>
                              <p>مجانا</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon-free"
                                width="25"
                                height="25"
                                viewBox="0 0 30 30"
                              >
                                <g clipPath="url(#clip0_265_562)">
                                  <path
                                    d="M29.0625 24.375C29.0625 26.9531 26.9531 29.0625 24.375 29.0625H5.625C3.04688 29.0625 0.9375 26.9531 0.9375 24.375V5.625C0.9375 3.04688 3.04688 0.9375 5.625 0.9375H24.375C26.9531 0.9375 29.0625 3.04688 29.0625 5.625V24.375Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M15.9375 19.2188H19.6875V17.8125H17.2969V15.7031H19.6875V14.2969H17.2969V12.1875H19.6875V10.7812H15.9375V19.2188ZM25.3125 12.1875V10.7812H21.5625V19.2188H25.3125V17.8125H22.9219V15.7031H25.3125V14.2969H22.9219V12.1875H25.3125ZM11.2031 19.2188V15.7031H11.7188L12.8906 19.2188H14.2969L13.0781 15.5156C13.9219 15.1406 14.5312 14.25 14.5312 13.2188C14.5312 11.8594 13.5 10.7812 12.1875 10.7812H9.84375V19.2188H11.2031ZM11.2031 12.1875H12.1875C12.75 12.1875 13.1719 12.6563 13.1719 13.2188C13.1719 13.7813 12.7031 14.25 12.1875 14.25H11.2031V12.1875ZM6.04687 19.2188V15.7031H8.4375V14.2969H6.04687V12.1875H8.4375V10.7812H4.6875V19.2188H6.04687Z"
                                    fill="#009539"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_265_562">
                                    <rect width="30" height="30" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </>
                          ) : (
                            <>
                              <p>مدفوع</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="iconDolar"
                                width="20"
                                height="20"
                                viewBox="0 0 320 512"
                              >
                                <path
                                  d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"
                                  fill="#ffff"
                                />
                              </svg>
                            </>
                          )}
                        </div> */}
                      </div>
                    </th>
                    <th
                      className={` basis- max-h-14 flex-auto text-left p-3 ${
                        courseData?.isFree 
                                                  ? "special-rowIcon"
                          : "iconCorsess"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => toggleMenu(index)}
                        width="20"
                        height="20"
                        className="iconDots"
                        viewBox="0 0 128 512"
                      >
                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                      </svg>

                      {openMenuIndex === index && (
                        <div
                          className="dropdown-menu EditAndDelet"
                          ref={dropdownRef}
                        >
                          <div className="dropdown-item edit" onClick={() => handleClick(courseData?.quizId)}>
                       

                            <p>تعديل</p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              className="icon-free"
                              viewBox="0 0 512 512"
                            >                            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" fill="black"/></svg>




                          </div>
                   
                        </div>
                      )}
                    </th>
                  </tr>

                  <span className={Style.brmedium}></span>
                </React.Fragment>
              ))}</>:<>
           </>}
                
                
             
            </tbody>
            <div className="space"></div>
          </table>
        </div>
      </div>
          </>}

    </>
  );
};

export default VideoDetails;
