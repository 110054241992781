import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './AddSubject.module.css'
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup'

const AddSubject = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    let {courseId}= useParams()

    const [Loading, setLoading] = useState(false);
    let navigate =useNavigate()

    async function submitLogin(values){
      setLoading(true);
  
  try {
    const response = await axios.post(`https://itc-541ea27f6158.herokuapp.com/api/v1/course-subject/${courseId}`, values,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
    });
    notify("تم أضافه الموضوع بنجاح", "success");

    console.log(response);
    navigate(`/${courseId}/subjects`);

  }   catch (error) {
    if (error.response.status === 405) {
      setLoading(false)
       navigate('/login') ;
       notify(error.response.data.message, "success");

    }
    setLoading(false)

    notify(error.response.data.message, "success");

    console.error('Error fetching profile data:', error);
  }

      }
      let validationSchema=Yup.object({
        title: Yup.string()
        .min(3, 'يجب أن يحتوي اسم الموضوع على الأقل 3 أحرف')
        .max(100, 'يجب ألا يتجاوز اسم الموضوع 100 حرفًا')
        .required('اسم الموضوع مطلوب'),
           
     
      description: Yup.string()
      .min(3, 'يجب أن يحتوي وصف الموضوع على الأقل 3 أحرف')
      .max(500, 'يجب ألا يتجاوز وصف الموضوع 500 حرفًا')
        .required('وصف الدورة مطلوب'),
      })
    let formik=useFormik({
        initialValues:{
            title:'',
            description:'',
        },
        validationSchema
        ,
        onSubmit:submitLogin
      })
    
    return <>
    <Helmet>
          <title>أضافة موضوع</title>
          </Helmet>
<div>
  
<div className={`${Style.home} bg-gray-100 min-h-screen     rounded-tr-3xl    `}>
    
<div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={`/${courseId}/subjects`} className="mr-2 text-green-500">قائمة الموضوعات</Link>
    </div>   
    
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
  <Link  className='font-bold text-green-600' >
    أضافه موضوع
  </Link>
    
</div>


            
<section class="bg-white rounded-xl">
  <div class=" p-4 mx-auto max-w-4xl">
      <form onSubmit={formik.handleSubmit}>
          <div class="grid gap-4 grid-cols-2">
              <div class="sm:col-span-2">
              {formik.errors.title && formik.touched.title? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.title}</span>
</div>
     </>:null}
                  <label for="title" class="block mb-2 text-sm font-medium text-green-600 ">أسم الموضوع</label>
                  <input maxLength="100" type="text" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.title} name="title" id="title" class="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg  block w-full p-2.5 outline-none" placeholder="أكتب اسم الموضوع "/>
              </div>
              <div class="sm:col-span-2">
              {formik.errors.description && formik.touched.description? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.description}</span>
</div>
     </>:null}
                  <label for="description" class="block mb-2 text-sm font-medium text-green-600 ">وصف الموضوع</label>
                  <textarea maxLength="500" id="description " onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.description} name='description' rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-green-500  outline-none" placeholder="أكتب وصف الموضوع"></textarea>
              </div>

          
          </div>
          {Loading?<>
            <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
  <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
              أضافه الموضوع
          </button>
  </>}
        
      </form>
  </div>
</section>
  
  
                    
  
  
            </div>
</div>
         
    </>
}

export default AddSubject;
