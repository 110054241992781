import React, { useState, useEffect, useRef } from "react";
import Style from "./VideosSection.module.css";
import "./Courses.css";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-toastify";

const VideoSection = () => {
   let {subjectId,courseId}= useParams()
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const dropdownRef = useRef(null);
  const [loading, setloading] = useState(false);


  const notify = (mess, type) => {
    toast[type](mess);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const navigate =useNavigate()

  const handleClick = (cousreIndex) => {
    navigate(`/${subjectId}/UpdateVideo/${courseId}/${cousreIndex}`)
  };
  const handelDeleteCousre = async (courseIndex) => {
    try {
      setloading(true);
      const response = await axios.delete(`https://itc-541ea27f6158.herokuapp.com/api/v1/subject-video/${courseIndex}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setloading(false);
      notify("تم حذف الفيديو بنجاح", "success");

      
      if (response.status === 200) {
        setCourseData(prevData => {
          return prevData.filter(course => course?.videoId !== courseIndex);
        });
      }
    } catch (error) {
      setloading(false);
      notify(error.response.data.message, "error");

      console.error('Error fetching course data:', error);
      // Handle error
    }
  };
    const [courseData, setCourseData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          setloading(true)
          const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/subject-video/subject/${subjectId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
          });
          setloading(false)
          setCourseData(response?.data.data);
        }    catch (error) {
          if (error.response.status === 405) {
            setloading(false)
            navigate('/login') ;
             notify(error.response.data.message, "success");
      
          }
          setloading(false)
      
          notify(error.response.data.message, "success");
      
          console.error('Error fetching profile data:', error);
        }
      };
    
  
      fetchData();
  
    }, []);
    console.log(courseData);
  return (
    <>
      <Helmet>
        <title>الفيديوهات</title>
      </Helmet>

      {loading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          
      <div className={`${Style.home} bg-gray-100 rounded-xl min-h-screen`}>
      <div className="  flex items-center bg-white p-5 rounded-xl my-5">

  <Link to={'/'} className=' text-green-500' >
قائمة الدورات التعليمية
  </Link>

     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <div className="flex items-center">
        <Link to={`/${courseId}/subjects`} className="mr-2 text-green-500">قائمة الموضوعات</Link>
    </div>   
    
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
  <Link className='font-bold text-green-600' >
        الفيديوهات
  </Link>
</div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="Corsess-Head">
              <tr className="flex flex-row  h-15">
                <th className="max-h-14 flage flex w-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="18"
                    viewBox="0 0 20 24"
                    fill="none"
                  >
                    <path
                      d="M0 0L1.50842 0L1.50842 24H0L0 0ZM21 6.75L3.01683 13.4531L3.01683 0.046875L21 6.75ZM4.52525 11.2969L16.7104 6.75L4.52525 2.20313L4.52525 11.2969Z"
                      fill="#009539"
                    />
                  </svg>
                </th>
                <th className=" Address mx-3 p-3 max-h-14 basis-5/6 ">
                  <div className="flex flex-row">
                    <div className="text-right basis-2/3">عنوان الفيديو</div>
                    <div className=" basis-1/3 text-center ">رابط الفيديو</div>
                  </div>
                </th>
                <th className="flex-none max-h-14 pt-1 p-2">
                  <Link to={`/${subjectId}/Addvedio/${courseId}`} type="button" className="button btn">
                    <span className="">اضافة فيديو</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="14" cy="14" r="14" fill="#FBFBFB" />
                      <path
                        d="M16 15.5H15.5V16V22.5C15.5 22.7652 15.3946 23.0196 15.2071 23.2071C15.0196 23.3946 14.7652 23.5 14.5 23.5C14.2348 23.5 13.9804 23.3946 13.7929 23.2071C13.6054 23.0196 13.5 22.7652 13.5 22.5V16V15.5H13H6.5C6.23478 15.5 5.98043 15.3946 5.79289 15.2071C5.60536 15.0196 5.5 14.7652 5.5 14.5C5.5 14.2348 5.60536 13.9804 5.79289 13.7929C5.98043 13.6054 6.23478 13.5 6.5 13.5H13H13.5V13V6.5C13.5 6.23478 13.6054 5.98043 13.7929 5.79289C13.9804 5.60536 14.2348 5.5 14.5 5.5C14.7652 5.5 15.0196 5.60536 15.2071 5.79289C15.3946 5.98043 15.5 6.23478 15.5 6.5V13V13.5H16H22.5C22.7652 13.5 23.0196 13.6054 23.2071 13.7929C23.3946 13.9804 23.5 14.2348 23.5 14.5C23.5 14.7652 23.3946 15.0196 23.2071 15.2071C23.0196 15.3946 22.7652 15.5 22.5 15.5H16Z"
                        fill="#009539"
                        stroke="#009539"
                      />
                    </svg>
                  </Link>
                </th>
              </tr>
            </thead>
            <span className={Style.brmedium}></span>
            <span className={Style.brmedium}></span>

            <tbody className="">
              
              {courseData?.map((course , cousreIndex)=>{
                return <>
                 {[cousreIndex+1].map((index) => (
                <React.Fragment key={index}>
                  <span className={Style.brmedium}></span>


                  <tr className="flex flex-row h-15 ">

                    <th
                      className={`max-h-14 flex w-16 ${
                        course?.isFree ? "special-flage" : "flage"
                                            }`}
                    >
                                        <Link to={`/${course?.videoId}/VideoExam`}  onClick={() => handleClick(course?.videoId)}>

                      {index}
                      </Link>
                    </th>
                    <th
                      className={`ms-3 p-3 max-h-14 basis-5/6 ${
                        course?.isFree                           ? "special-row"
                          : "bodyCorsess"
                      }`}
                    >
                      <div className="flex flex-row">
                        <div className="text-right basis-2/3 ">
                        <Link
    to={`/${course?.videoId}/VideoExam`}
    onClick={() => handleClick(course?.videoId)}
    title={course?.videoTitle}
  >
    {course?.videoTitle.length > 80 ? `${course?.videoTitle.slice(0, 80)}...` : course?.videoTitle}
  </Link>
</div>
<div className="flex text-center paymentOrFree basis-1/3 text-blue-600 underline">
  <Link     title={course?.videoUrl}
 to={course?.videoUrl}>
    {course?.videoUrl?.length > 60 ? `${course?.videoUrl.slice(0, 60)}...` : course?.videoUrl}
  </Link>
</div>
                      </div>
                    </th>
                    <th
                      className={` basis- max-h-14 flex-auto text-left p-3 ${
                        course?.isFree 
                                                  ? "special-rowIcon"
                          : "iconCorsess"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => toggleMenu(index)}
                        width="20"
                        height="20"
                        className="iconDots"
                        viewBox="0 0 128 512"
                      >
                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                      </svg>

                      {openMenuIndex === index && (
                        <div
                          className="dropdown-menu EditAndDelet"
                          ref={dropdownRef}
                        >
                          <div className="dropdown-item edit" onClick={() => handleClick(course?.videoId)}>
                       

                            <p>تعديل</p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              className="icon-free"
                              viewBox="0 0 512 512"
                            >                            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" fill="black"/></svg>




                          </div>
                          <div className="dropdown-item delete" onClick={()=>document.getElementById('my_modal_2').showModal()} >
                            <p>حذف</p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              className="icon-free"
                              viewBox="0 0 448 512"
                            >
                              <path
                                d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                                fill="red"
                              />
                            </svg>
                          </div>
                          <dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4 text-center">هل انت متأكد من حذف الفيديو ؟</h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
        {/* if there is a button in form, it will close the modal */}
               <button onClick={() => handelDeleteCousre(course?.videoId)} class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>
                        </div>
                      )}
                    </th>
                  </tr>

                  <span className={Style.brmedium}></span>
                </React.Fragment>
              ))}
                
                </>
              })}
             
            </tbody>
            <div className="space"></div>
          </table>
        </div>
      </div>
          </>}

    </>
  );
};

export default VideoSection;
