import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import './App.css';
import Home from './Components/Home/Home';
import NotFound from './Components/NotFound/NotFound';
import Subject from './Components/Subject/Subject';
import VideosSection from './Components/VideosSection/VideosSection';
import SubScripe from './Components/My_subscriptions/My_subscriptions';
import Exams from './Components/Exams/Exams';
import Complaints from './Components/Complaints/Complaints';
import ComplaintsDetails from './Components/ComplaintsDetails/ComplaintsDetails';
import NewComplaints from './Components/NewComplaints/NewComplaints';
import Login from './Components/Login/Login';
import FinalExam from './Components/FinalExam/FinalExam';
import UserContextProvider, { UserContext } from './Context/userContext';
import ProtectRoute from './Components/ProtectRoute/ProtectRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Question } from './Components/Question/Question';
import SubjectSubScription from './Components/Subject_SubScription/Subject_SubScription';
import AddSubject from './Components/AddSubject/AddSubject';
import Addvedio from './Components/Addvedio/Addvedio';
import VideoDetails from './Components/VideoDetails/VideoDetails';
import UpdateCourse from './Components/updateCourse/updateCourse';
import UpdateSubject from './Components/updateSubject/updateSubject';
import UpdateVedio from './Components/updatevedio/updatevedio';
import { UpdateQuestion } from './Components/updateQusetions/updateQusetions';
import AddExam from './Components/AddExam/AddExam';
import { Profile } from './Components/Profile/Profile';
import UpdateQuiz from './Components/UpdateQuiz/UpdateQuiz';
import axios from 'axios';

const App = () => {
  let { setuserToken } = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem('userToken') !== null) {
      setuserToken(localStorage.getItem('userToken'));
    } else {
      return <Navigate to={'/login'} />; // Navigate to login page
    }
  }, [setuserToken]);

  return (
    <>
      <ToastContainer theme='colored' position="top-right" autoClose={1000} />
      <Router>
        <Routes>
          
          <Route path="/" element={<Layout />}>
            <Route index element={<ProtectRoute> <Home /> </ProtectRoute>} />
            <Route path="/Profile" element={<ProtectRoute> <Profile /> </ProtectRoute> } />
            <Route path="/:courseId/subjects" element={ <ProtectRoute><Subject /> </ProtectRoute>} />
            <Route path="/:subjectId/Videos/:courseId" element={<ProtectRoute><VideosSection /></ProtectRoute> } />
            <Route path="/Participating_students" element={<ProtectRoute><Exams /></ProtectRoute> } />
            <Route path="/My_Complaints" element={<ProtectRoute><Complaints /></ProtectRoute> } />
            <Route path="/My_Complaints/ComplaintsDetails/:complaintId" element={<ProtectRoute><ComplaintsDetails /></ProtectRoute>} />
            <Route path="/Registered_students" element={<ProtectRoute><NewComplaints /></ProtectRoute>} />
            <Route path="/Subscription_requests" element={<ProtectRoute><SubScripe/></ProtectRoute>} />
            <Route path="/:quizId/Question/:vedioId" element={<ProtectRoute><Question /></ProtectRoute>} />
            <Route path="/:quizId/UpdateQuestion/:vedioId" element={<ProtectRoute><UpdateQuestion /></ProtectRoute> } />
            <Route path="/AddCourse" element={<ProtectRoute><SubjectSubScription /></ProtectRoute> } />
            <Route path="/UpdateCourse/:courseId" element={<ProtectRoute><UpdateCourse /></ProtectRoute> } />
            <Route path="/UpdateSubject/:SubjectId" element={<ProtectRoute> <UpdateSubject /></ProtectRoute>} />
            <Route path="/:vedioId/UpdateQuiz/:QuizId" element={<ProtectRoute><UpdateQuiz /></ProtectRoute> } />
            <Route path="/:SubjectId/UpdateVideo/:courseId/:VedioId" element={<ProtectRoute> <UpdateVedio /></ProtectRoute>} />
            <Route path="/:courseId/AddSubject" element={<ProtectRoute> <AddSubject /></ProtectRoute>} />
            <Route path="/:vedioId/AddExam" element={<ProtectRoute><AddExam /></ProtectRoute> } />
            <Route path="/:subjectId/Addvedio/:courseId" element={<ProtectRoute><Addvedio /></ProtectRoute> } />
            <Route path="/:vedioId/VideoExam" element={<ProtectRoute> <VideoDetails /></ProtectRoute>} />
            <Route path="/:vedioId/ExamDetails" element={<ProtectRoute> <FinalExam /></ProtectRoute>} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
