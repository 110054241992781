import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './Addvedio.module.css'
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup'

const Addvedio = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    let {subjectId,courseId}= useParams()

    const [Loading, setLoading] = useState(false);
    let navigate =useNavigate()

    async function submitLogin(values){
        console.log(values);
      setLoading(true);
  
  try {
    const response = await axios.post(`https://itc-541ea27f6158.herokuapp.com/api/v1/subject-video/${subjectId}`, values,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
    });
    
    console.log(response);
    notify("تم اضافه الفيديو بنجاح", "success");

    navigate(`/${subjectId}/Videos/${courseId}`);

  } catch (error) {
    if (error.response.status === 405) {
      setLoading(false)
       navigate('/login') ;
       notify(error.response.data.message, "success");

    }
    setLoading(false)

    notify(error.response.data.message, "success");

    console.error('Error fetching profile data:', error);
  }
      }
      let validationSchema=Yup.object({
        videoTitle: Yup.string()
        .min(3, 'يجب أن يحتوي اسم الفيديو على الأقل 3 أحرف')
        .max(100, 'يجب ألا يتجاوز اسم الفيديو 100 حرفًا')
        .required('اسم الفيديو مطلوب'),
        videoUrl: Yup.string()
        .min(10, 'يجب أن يحتوي لينك الفيديو على الأقل 3 أحرف')
        .max(200, 'يجب ألا يتجاوز لينك الفيديو 200 حرفًا')
        .required('لينك الفيديو مطلوب'),
           
        videoDescription: Yup.string()
      .min(3, 'يجب أن يحتوي وصف الفيديو على الأقل 3 أحرف')
      .max(500, 'يجب ألا يتجاوز وصف الفيديو 500 حرفًا')
        .required('وصف الفيديو مطلوب'),
      })
    let formik=useFormik({
        initialValues:{
            videoTitle:'',
            videoDescription:'',
            videoUrl:'',
        },
        validationSchema
        ,
        onSubmit:submitLogin
      })
    
    return <>
    <Helmet>
          <title>أضافة فيديو</title>
          </Helmet>
<div>
  
<div className={`${Style.home} bg-gray-100 min-h-screen     rounded-tr-3xl    `}>
    
<div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/'} className="mr-2 text-green-500">قائمة الدورات التعليمية</Link>
    </div>   
    
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={'/My_subscriptions/Subject'} className='font-bold text-green-600'>اسم الفيديو</Link>
</div>


            
<section class="bg-white rounded-xl">
  <div class=" p-4 mx-auto max-w-4xl">
      <form onSubmit={formik.handleSubmit}>
          <div class="grid gap-4 grid-cols-2">
              <div class="sm:col-span-2">
              {formik.errors.videoTitle && formik.touched.videoTitle? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.videoTitle}</span>
</div>
     </>:null}
                  <label for="title" class="block mb-2 text-sm font-medium text-green-600 ">أسم الفيديو</label>
                  <input maxLength='100' type="text" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.videoTitle} name="videoTitle" id="title" class="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg  block w-full p-2.5 outline-none"
                   placeholder="أكتب اسم الفيديو" 
                   />
              </div>
              <div class="sm:col-span-2">
              {formik.errors.videoDescription && formik.touched.videoDescription? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.videoDescription}</span>
</div>
     </>:null}
                  <label for="description" class="block mb-2 text-sm font-medium text-green-600 ">الديسكريبشن</label>
                  <textarea maxLength='500' id="description " onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.videoDescription} name='videoDescription' rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-green-500  outline-none"
                   placeholder="وصف الفيديو"></textarea>
              </div>
              <div class="sm:col-span-2">
              {formik.errors.videoUrl && formik.touched.videoUrl? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.videoUrl}</span>
</div>
     </>:null}
                  <label for="videoUrl" class="block mb-2 text-sm font-medium text-green-600 ">رابط الفيديو</label>
                  <input maxLength='200' type="text" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.videoUrl} name="videoUrl" id="videoUrl" class="bg-gray-50 border-2 border-green-500 text-blue-700 text-sm rounded-lg  block w-full p-2.5 outline-none" 
                  placeholder="رابط الفيديو"/>
              </div>

          
          </div>
          {Loading?<>
            <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
  <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
              أضافه الفيديو
          </button>
  </>}
        
      </form>
  </div>
</section>
  
  
                    
  
  
            </div>
</div>
         
    </>
}

export default Addvedio;
