import React, { useEffect } from 'react';
import Style from './ProtectRoute.module.css';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

export default function ProtectRoute(props) {

  // Check if user is authenticated
  if (localStorage.getItem('userToken') !== null) {
    return props.children; // Render child components
  } else {
    return <Navigate to={'/login'} />; // Navigate to login page
  }
}
