import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './updateSubject.module.css';
import { useForm } from 'react-hook-form'; // Import useForm from react-hook-form instead of useFormik
import axios from 'axios';
import { toast } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';

const UpdateSubject = () => {
  const { SubjectId } = useParams();
  const [data, setData] = useState(null);
  
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/course-subject/${SubjectId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setLoading(false);
        setData(response?.data.data);
      }   catch (error) {
        if (error.response.status === 405) {
          setLoading(false)
          navigate('/login') ;
           notify(error.response.data.message, "success");
    
        }
        setLoading(false)
    
        notify(error.response.data.message, "success");
    
        console.error('Error fetching profile data:', error);
      }
    };

    fetchData();
  }, [SubjectId]);

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm(); // Destructure methods from useForm hook

  useEffect(() => {
    if (data) {
      setValue('title', data?.subjectTitle);
      setValue('description', data?.subjectDescription);
    }
  }, [data, setValue]);
  console.log(data);

  const onSubmit = async (values) => {
    console.log("Updated data:", values);
    try {
        setLoading(true);
        const response = await axios.put(`https://itc-541ea27f6158.herokuapp.com/api/v1/course-subject/${SubjectId}`, values,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setLoading(false);
        notify("تم تعديل الموضوع بنجاح", "success");
        navigate(`/${data?.courseId}/subjects`)
        setData(response?.data.data);
      } catch (error) {
        setLoading(false);
        notify(error?.response.data.message, "error");
        console.error('Error fetching course data:', error);
        // Handle error
      }
 
  
  };

  return (
    <>
      <Helmet>
        <title>تعديل الموضوع</title>
      </Helmet>
      {loading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
          <div className={`${Style.home} bg-gray-100 min-h-screen rounded-tr-3xl`}>
      <div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={`/${data?.courseId}/subjects`} className="mr-2 text-green-500">قائمة الموضوعات</Link>
    </div>   
    
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
  <Link to={'/'} className='font-bold text-green-600' >
    {data?.courseName}
  </Link>
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
  <Link to={`/UpdateSubject/${SubjectId}`} className='font-bold text-green-600' >
    {data?.subjectTitle}
  </Link>
</div>

        <section className="bg-white rounded-xl">
          <div className="p-4 mx-auto max-w-4xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-4 grid-cols-2">
                <div className="sm:col-span-2">
                {errors.title && 
                <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
                <span className="block sm:inline">{errors.title.message}</span>
              </div>
                }
                  <label htmlFor="title" className="block mb-2 text-sm font-medium text-green-600 ">أسم الدوره التعليمية</label>
                  <input
                    type="text"
                    {...register("title", { required: "أسم الموضوع مطلوب"  , min:{value:3 ,message:'يجب ألا يتجاوز اسم الموضوع 3 أحرف'} ,maxLength: { value: 100, message: "يجب ألا يتجاوز اسم الموضوع 100 حرفًا" }})}
                    maxLength='100'
                    id="title"
                    className="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
                    placeholder="اكتب اسم الموضوع"
                  />
                </div>
                <div className="sm:col-span-2">
                {errors.description && 
                <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
                <span className="block sm:inline">{errors.description.message}</span>
              </div>
                }
                  <label htmlFor="description" className="block mb-2 text-sm font-medium text-green-600 ">الديسكريبشن</label>
                  <textarea
                    id="description"
                    {...register("description", { required: "وصف الموضوع مطلوب"  , min:{value:3 ,message:'يجب ألا يتجاوز وصف الموضوع 3 أحرف'} ,maxLength: { value: 500, message: "يجب ألا يتجاوز وصف الموضوع 500 حرفًا" }})}
                    maxLength='500'
                    rows="8"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-green-500  outline-none"
                    placeholder="وصف الموضوع"
                  />
                </div>
             
              </div>
              {loading ? (
                <button type="submit" className="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
                  <i className='fas fa-spinner fa-spin'></i>
                </button>
              ) : (
                <button type="submit" className="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
                  تعديل الموضوع
                </button>
              )}
            </form>
          </div>
        </section>
      </div>
          </>}
    
    </>
  );
};

export default UpdateSubject;
