/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from 'react';
import Style from './Navbar.module.css'
import './nav.css'
import imge from '../../images/logo512.jpg'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo512.jpg'
import { UserContext } from '../../Context/userContext';
import { toast } from 'react-toastify';


const Navbar = ({ toggleSide }) => {
  let {setuserToken}= useContext(UserContext)
  const user=    localStorage.getItem('user' )
  const notify = (mess, type) => {
    toast[type](mess);
  };

  let navigate =useNavigate()

  function logout () {
    notify("تم تسجيل الخروج بنجاح", "success");
    navigate('/login')
    setuserToken(null)
    localStorage.removeItem('userToken' )
    localStorage.removeItem('user' )
  }

    return <>
  <div className={`flex fixed z-20 top-0 left-0 w-12/12 right-0 justify-between gap-4 navbar bg-gray-50 `}>

  <div className={Style.dropDown} onClick={toggleSide}>
<svg
    width="45"
    height="45"
    viewBox="0 0 448 512"
    className='iconBar'
    
    xmlns="http://www.w3.org/2000/svg"
    >            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
  </svg>
</div>

<Link to={''} className=' rounded-xl ps-24 max-md:ps-0 font-bold max-md:w-11/12 '	>
<p>دورة المنقذ  <span className='px-1 max-md:hidden text-green-600'>ITC</span></p> 
  </Link>

  <div className=''>

<form className="w-80 max-lg:w-40 m-0 p-0 mx-auto max-md:hidden   ">   
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            {/* <svg className="w-4 h-4 text-green-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg> */}
        </div>
        {/* <input type="search" id="default-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-green-400 rounded-lg bg-gray-50 outline-none" placeholder="ابحث هنا ..."  /> */}
    </div>
</form>
</div>


 
    <div className="dropdown dropdown-end max-md:w-11/12">
      <div tabIndex={0} role="button" className="btn  btn-ghost bg-transparent	border-none hover:bg-transparent">
      <div className={Style.content}>
      <div className="flex ">
  <div className="flex-none  ">
  <svg width="30" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 35C7.5 35 5 35 5 32.5C5 30 7.5 22.5 20 22.5C32.5 22.5 35 30 35 32.5C35 35 32.5 35 32.5 35H7.5ZM20 20C21.9891 20 23.8968 19.2098 25.3033 17.8033C26.7098 16.3968 27.5 14.4891 27.5 12.5C27.5 10.5109 26.7098 8.60322 25.3033 7.1967C23.8968 5.79018 21.9891 5 20 5C18.0109 5 16.1032 5.79018 14.6967 7.1967C13.2902 8.60322 12.5 10.5109 12.5 12.5C12.5 14.4891 13.2902 16.3968 14.6967 17.8033C16.1032 19.2098 18.0109 20 20 20Z" fill="#009539"/>
</svg>

  </div>
  <div className={`grow ps-2 pt-3 max-md:pt-2    `}>
{user}  
    <span className='ps-2  max-sm:hidden'><i className="fa-solid fa-sm fa-chevron-down text-green-600"></i></span>
  </div>

</div>
        
      
     
      </div>
      </div>
      <div tabIndex={0} className="mt-3 z-50 card card-compact dropdown-content w-40 bg-base-100 ">
        <div className={`card-body text-green-600 text-center ${Style.Link}`}>
          <Link to={'/Profile'} className='btn text-md cursor-pointer'>Profile</Link>
          <span className="font-bold btn text-md cursor-pointer	" onClick={logout}>تسجيل خروج</span>
        </div>
      </div>
    </div>

   

</div>






 </>
       
}

export default Navbar;
