import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './FinalExam.module.css';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const FinalExam = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    
      let navigate =useNavigate()

    const [examDetails, setExamDetails] = useState(null);
    const [loading, setLoading] = useState(false);
   
    let {vedioId}= useParams();
    console.log(examDetails);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes?videoId=${vedioId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                });
                setLoading(false);
                setExamDetails(response?.data?.data);
            }    catch (error) {
                if (error.response.status === 405) {
                    setLoading(false)
                  navigate('/login') ;
                   notify(error.response.data.message, "success");
            
                }
                setLoading(false)
            
                notify(error.response.data.message, "success");
            
                console.error('Error fetching profile data:', error);
              }
        };
        fetchData()

    }, [vedioId]);
const [questionIdToDelete, setQuestionIdToDelete] = useState(null);


    const handeldeleteQuestions = async (courseIndex) => {
        try {
            setLoading(true);
          const response = await axios.delete(
            `https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes/questions/${courseIndex}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
              }
              
            }
          );
          setLoading(false);
          notify("تم حذف السؤال بنجاح", "success");
        
          const updatedQuestions = examDetails.questions.filter(question => question.id !== courseIndex);
          // Update examDetails with the filtered questions
          setExamDetails(prevState => ({
              ...prevState,
              questions: updatedQuestions
          }));        
        } catch (error) {
          setLoading(false);
          notify(error.response.data.message, "error");
    
          console.error('Error deleting course subscription:', error);
          // Handle error
        }
      };
    return <>

         
                {loading?<>
    <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>
          </>:
          <>
          <div className={`${Style.home} bg-gray-100 min-h-screen rounded-tr-3xl`}>
          <div className="  flex items-center bg-white p-5 rounded-xl my-5">

<Link to={'/'} className=' text-green-500' >
قائمة الدورات التعليمية
</Link>

   <div className="flex items-end pt-1">
      <span className='px-2'>
          <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
          </svg>
      </span>
  </div>
 
  <div className="flex items-center">
      <Link to={`/${vedioId}/VideoExam`} className="mr-2 text-green-500">قائمة الاختبار</Link>
  </div>   
  
   <div className="flex items-end pt-1">
      <span className='px-2'>
          <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
          </svg>
      </span>
  </div>
<Link className='font-bold text-green-600' >
      الأختبار
</Link>
</div>
            <div className='text-end'>
                <Link to={`/${examDetails?.quizId}/Question/${vedioId}`} className='btn bg-customGreen text-white hover:bg-green-700'>أضافه سؤال</Link>
            </div>
                <div className="bg-white p-5 rounded-xl my-5 flex justify-between">
                    <div></div>
                    <p to={'/Subject'} className='font-bold text-progress_text mb-2'>{examDetails?.quizTitle}</p>
                    <p to={'/Subject'} className='text-customGreen '>{examDetails?.questions.length} سؤال</p>
                </div>
                {examDetails?.questions.map((question) => (
                    <div key={question?.id} className='bg-white p-10 rounded-2xl border-green-600 mt-3 border-2'>
                        <div className='flex flex-row items-center justify-between'>
                            <div>
                            {/* <span className='bg-customGreen rounded-full p-1 m-2'></span> */}
                            <h1 className='text-lg text-black'> <span className='text-green-600'>  السؤال :</span>  {question?.question}</h1>
                            </div>
                          <div>
                            <Link to={`/${question?.id}/UpdateQuestion/${vedioId}`} className='btn bg-customGreen hover:bg-green-600 text-white'>تعديل</Link>
                            <button onClick={() => {
        document.getElementById('my_modal_2').showModal();
        setQuestionIdToDelete(question?.id);
    }} className='btn ms-2 bg-red-600 hover:bg-red-700 text-white'>
    حذف
</button>
                            <dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4 text-center">هل انت متأكد من حذف الموضوع ؟</h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
        {/* if there is a button in form, it will close the modal */}
               <button onClick={() => handeldeleteQuestions(questionIdToDelete)} class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>




                          </div>
                        </div>
                        
                        <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-5 justify-center items-center '>
                            <div className='grid grid-cols-2 gap-5 max-md:w-11/12 col-span-2'>
                            {Object.entries(question).map(([key, value]) => {
    if (key.startsWith('answer')) {
        const isCorrect = value === question?.correctAnswer;
        return (
            <div className='' key={key}>
                <div className="block">
                    <div className="text-lg font-semibold">
                        <h2 className={isCorrect ? 'bg-customGreen text-white w-fit p-3 rounded-xl' : 'bg-gray-200 w-fit p-3 rounded-xl'}>{value}</h2>
                    </div>
                </div>
            </div>
        );
    }
    return null;
})}
                            </div>
                            <div className='w-full max-lg:order-first max-lg:pb-2 '>
                            <a href={question?.imageUrl} target="_blank" rel="noopener noreferrer">
                            <img src={question?.imageUrl} className='w-8/12 h-auto rounded-lg' alt="" srcSet="" />
                            </a>
                            </div>
                        </div>
                    </div>
                ))}
             
            </div>
          </>}
            
         
            </>
          
    


          
    
};

export default FinalExam;
