import React, { useEffect } from 'react';
import Style from './Layout.module.css'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
const Layout = () => {

 
  
    return <>


    <Navbar/>
<div class="flex ">
    <div className='w-1/12'>
        <Sidebar/>

    </div>
    <div class="flex-1 w-11/12 ">
        <Outlet/>
    </div>
</div>
    </>
       
}

export default Layout;
