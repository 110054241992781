import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './Complaints.module.css'
import { BallTriangle } from 'react-loader-spinner';
import axios from 'axios';
import { toast } from 'react-toastify';

const Complaints = () => {
  const navigate=useNavigate()
  const notify = (mess, type) => {
    toast[type](mess);
  };
       const [courseData, setCourseData] = useState(null);
       const [loading, setloading] = useState(false);
       function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('ar-EG', options);
      }
      const [currentPage, setCurrentPage] = useState(1);

       useEffect(() => {
         const fetchData = async () => {
           try {
             setloading(true)
             const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/complaints?complaintStatus=ALL&page=${currentPage}&size=10`, {
               headers: {
                 Authorization: `Bearer ${localStorage.getItem('userToken')}`
               }
             });
             setloading(false)
             setCourseData(response?.data.data);
           } 
           catch (error) {
            if (error.response.status === 405) {
              setloading(false)
              navigate('/login') ;
               notify(error.response.data.message, "success");
        
            }
            setloading(false)
        
            notify(error.response.data.message, "success");
        
            console.error('Error fetching profile data:', error);
          }
          
         };
       
     
         fetchData();
     
       }, [currentPage]);
       const totalPages = courseData?.totalPages;

       const handlePageChange = (page) => {
           setCurrentPage(page);
       };  
       function navigateto (cousreIndex) {
        console.log(cousreIndex);
        navigate(`/My_Complaints/ComplaintsDetails/${cousreIndex}`)
       }
       console.log(courseData);
       return (
         <>
           <Helmet>
             <title>الطلاب المشتركين</title>
           </Helmet>
           {loading?<>
                     <div className={`${Style.home2} w-full flex justify-center`}>
                     <BallTriangle
       height={100}
       width={100}
       radius={5}
       color="#4fa94d"
       ariaLabel="ball-triangle-loading"
       wrapperStyle={{}}
       wrapperClass=""
       visible={true}
       />
     
     </div>
               </>:<>
               <div className={`${Style.home} bg-gray-100  rounded-xl min-h-screen  `}>
       
            <table className="Exam-table">
               <thead className="Exam-table-Head">
                 <tr className="flex flex-row  h-15">
                   <th className="max-h-14 flage flex w-16">
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="25"
                       height="18"
                       viewBox="0 0 20 24"
                       fill="none"
                     >
                       <path
                         d="M0 0L1.50842 0L1.50842 24H0L0 0ZM21 6.75L3.01683 13.4531L3.01683 0.046875L21 6.75ZM4.52525 11.2969L16.7104 6.75L4.52525 2.20313L4.52525 11.2969Z"
                         fill="#009539"
                       />
                     </svg>
                   </th>
                   <th className=" Address-Exam mx-3 p-3 max-h-14 basis-5/6 ">
                     <div className="flex flex-row">
                       <div className="text-right basis-1/4">اسم الطالب</div>
                       <div className="text-center basis-1/4">تاريخ الشكوي</div>
                       <div className="text-center basis-1/4">رقم الهاتف</div>
                       <div className="text-center basis-1/4">الدولة</div>
                     </div>
                   </th>
                   <th className="flex-none Address-Exam-red max-h-14 pt-4 p-6 text-red-600">الحاله</th>
                 </tr>
               </thead>
     
               <tbody>
               {courseData?.items?.map((course , cousreIndex)=>{
                 return <>
                   {[cousreIndex+1].map((index) => (
     <>
                 <span className={Style.brmedium}></span>
                 <span className={Style.brmedium}></span>
     
                 <tr className="flex flex-row h-15   " >
                 <th onClick={()=>navigateto(course?.complaintId)} className={`max-h-16 flex w-16 complian-Exam cursor-pointer ${course.status === 'SOLVED' ? 'text-green-600' : 'text-red-600'}`}>{index}</th>
                   <th className=" Address-Exam-Body mx-3 p-3 max-h-14 basis-5/6 ">
                     <div className="flex flex-row">
                       <div onClick={()=>navigateto(course?.complaintId)} className={`text-right basis-1/4 cursor-pointer ${course.status === 'SOLVED' ? 'text-green-600' : 'text-red-600'}`}>{course?.studentName}</div>
                       <div className={`text-center basis-1/4 ${course.status === 'SOLVED' ? 'text-green-600' : 'text-red-600'}`}> {formatDate(course?.date)}</div>
                       <div className={`text-center basis-1/4 ${course.status === 'SOLVED' ? 'text-green-600' : 'text-red-600'}`}>{course?.studentPhone}</div>
                       <div className={`text-center basis-1/4 ${course.status === 'SOLVED' ? 'text-green-600' : 'text-red-600'}`}>
                         <p>{course?.studentCountry}</p>
                       </div>
                     </div>
                   </th>
                   {course?.status === 'SOLVED' ?<>
                   <th  className=" text-sm flex-none   text-green-600 rounded-2xl bg-white max-h-14 py-3    px-5 w-auto">
                    تم حلها
                    </th></>:<>
                   <th  className=" text-sm flex-none bg-white rounded-2xl text-red-600 max-h-14  w-auto p-2.5 pt-3">
                   لم يتم حلها
                    </th>
                   </>}
                
                
                 </tr>
          
               </>
               ))}
                 
                 </>
               })}
             
               </tbody>
             </table>
            {/* </>} */}
            <nav aria-label="Page navigation example">
            {totalPages!==0?<>
            <ul className="flex items-center justify-center my-10 -space-x-px h-10 text-base">
    {currentPage==1?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage - 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Previous</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
        </svg>
      </a>
    </li></>}

    {Array.from({ length: Math.min(totalPages, 10) }, (_, index) => {
      const page = currentPage > 10 ? currentPage - 9 + index : index + 1;
      return (
        <li key={page}>
          <a
            className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage === page ? 'font-bold border-green-600 text-green-500' : 'text-gray-500'}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        </li>
      );
    })}
      {totalPages==currentPage?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage + 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Next</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
        </svg>
      </a>
    </li></>}
   
  </ul>
          </>:<>
          </>}
</nav>
     
           
             </div>
               </>}
                             
         
        
         </>
       );
}

export default Complaints;
