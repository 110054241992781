import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Style from './ComplaintsDetails.module.css'
import { BallTriangle } from 'react-loader-spinner';
import axios from 'axios';
import { format ,parseISO } from 'date-fns';
import { ar } from 'date-fns/locale';
import { toast } from 'react-toastify';

const ComplaintsDetails = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    let {complaintId}=useParams()

    const [checked, setchecked] = useState(false);
   async function changeStatus () {
        try {
            // Fetch data from the API using Axios
            setloading(true)
            const response = await axios.post(
                `https://itc-541ea27f6158.herokuapp.com/api/v1/complaints/${complaintId}/mark-as-solved`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('userToken')}`
                    }
                }
            );
        setchecked(true)
        setloading(false)

        console.log(response?.data.data);
            const data = response?.data.data;
                 notify("تم تعديل الشكوي بنجاح", "success");

            setComplaintData(data);
            
          } catch (error) {
            notify(error?.message, "error");

            setloading(false)
            // Handle errors, such as network errors or API errors
            console.error('Error fetching data:', error);
          }
    }
  
    const [loading, setloading] = useState(false);
    const [formattedDate, setformattedDate] = useState();
    
    const [complaintData, setComplaintData] = useState(null);
    const [isChecked, setIsChecked] = useState(complaintData?.status === "SOLVED");
const navigate=useNavigate()
console.log(complaintData);
    useEffect(() => {
        const fetchData = async () => {
          try {
            // Fetch data from the API using Axios
            setloading(true)
            const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/complaints/${complaintId}`,{
                headers:{
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
        });
            
            const data = response.data.data;
            const timestamp = '2024-03-04 09:26:57.720';
            const parsedDate = parseISO(timestamp);
            const formattedDate = format(parsedDate, "d MMMM yyyy", { locale: ar }); // Assuming 'ar' is the locale for Arabic

            setformattedDate(formattedDate)
            setloading(false)
            setComplaintData(data);
            
          }    catch (error) {
            if (error.response.status === 405) {
              setloading(false)
              navigate('/login') ;
               notify(error.response.data.message, "success");
        
            }
            setloading(false)
        
            notify(error.response.data.message, "success");
        
            console.error('Error fetching profile data:', error);
          }
          
         
        };
    
        // Call the fetchData function when the component mounts
        fetchData();
      }, []);
    return <>
    <Helmet>
    <title>الشكوي</title>
    </Helmet>
    {loading?<>
        <div className={`${Style.home2} w-full flex justify-center`}>
            <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
          </div>

    </>:<>
    <div className={`${Style.home} bg-gray-100 w-full rounded-xl min-h-screen  `}>
    <div className="  flex items-center  bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/My_Complaints'} className="mr-2 text-green-500">قائمة الشكاوي</Link>
    </div>   
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={'/My_Complaints/ComplaintsDetails/1'} className='font-bold text-green-600'>عرض الشكوي</Link>
</div>

<div className={`border-2 ${complaintData?.status === 'SOLVED' ? 'border-green-600' : 'border-red-600'} bg-white py-5 rounded-xl my-5 max-sm:px-5 px-10`}>
<div className="text-center">
{complaintData?.status==="SOLVED"?<>
<p  className="mr-2 text-2xl font-bold text-customGreen">{formattedDate}</p>
</>:<>
<p  className="mr-2 text-2xl font-bold text-red-600">{formattedDate}</p>
</>}
    </div>   
    <hr className={`${Style.hr} ${complaintData?.status === 'SOLVED' ? 'bg-green-600' : 'bg-red-600'} mt-2`} />

   
<p className='text-xl text-gray-400 p-2 max-sm:px-0 max-sm:text-lg leading-8	 ' style={{color:'#4D4C4CCC'}}>
{complaintData?.complaintDetails}
 </p>


 <div className="text-center flex felx-row justify-center pt-2 ">

    
     

    </div>   
       
    </div>
         
   


<ul class="grid w-full gap-6 md:grid-cols-2">
    {complaintData?.status==="SOLVED"?<>
    <li>
        <input  checked type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="inline-flex border-2 items-center justify-between w-full p-5 text-green-600 bg-white  border-gray-200 rounded-lg cursor-pointer  peer-checked:border-green-600 peer-checked:text-green-600">
            <div class="block">
                <div class="w-full text-lg font-semibold">تم حل المشكلة بنجاح</div>
            </div>
            <i class="fa-solid text-green-600 fa-xl fa-check"></i>
        </label>
    </li>
    <li>
        <input  disabled  type="radio" id="hosting-small" name="hosting" value="hosting-small" class="hidden peer" required />
        <label for="hosting-small" class="inline-flex items-center justify-between w-full p-5 text-red-600 bg-white border-2 border-gray-200 rounded-lg cursor-pointer  peer-checked:border-red-600 peer-checked:text-red-600">                           
            <div class="block">
                <div class="w-full text-lg font-semibold">سيتم حلها في وقت لاحق</div>
                </div>
                <i class="fa-solid text-red-500 fa-xl fa-spinner fa-spin"></i>
        </label>
    </li>
   
    </>:<>
    <li>
        <input onClick={()=>document.getElementById('my_modal_2').showModal()}     checked={checked} 
 type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="inline-flex border-2 items-center justify-between w-full p-5 text-green-600 bg-white  border-gray-200 rounded-lg cursor-pointer  peer-checked:border-green-600 peer-checked:text-green-600">
            <div class="block">
                <div class="w-full text-lg font-semibold">تم حل المشكلة بنجاح</div>
            </div>
            <i class="fa-solid text-green-600 fa-xl fa-check"></i>
        </label>
    </li>
    <li>
        <input checked disabled  type="radio" id="hosting-small" name="hosting" value="hosting-small" class="hidden peer" required />
        <label for="hosting-small" class="inline-flex items-center justify-between w-full p-5 text-red-600 bg-white border-2 border-gray-200 rounded-lg cursor-pointer  peer-checked:border-red-600 peer-checked:text-red-600">                           
            <div class="block">
                <div class="w-full text-lg font-semibold">سيتم حلها في وقت لاحق</div>
                </div>
                <i class="fa-solid text-red-500 fa-xl fa-spinner fa-spin"></i>
        </label>
    </li>
 
   
    </>}

</ul>



            
<dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4">هل انت متأكد من تغيير حاله الشكوي  </h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
        {/* if there is a button in form, it will close the modal */}
               <button onClick={()=>changeStatus()}  class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>

    </div>
    </>}

</>
}

export default ComplaintsDetails;
