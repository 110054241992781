import React from 'react';
// import Style from './NotFound.module.css'

const NotFound = () => {
    return <>
    <h1>NotFound</h1>
    </>
       
}

export default NotFound;
