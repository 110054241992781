import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './NewComplaints.module.css'
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import "./Exam.css";
import { toast } from 'react-toastify';


const NewComplaints = () => {
    const [courseData, setCourseData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [studentId, setStudentId] = useState(null);

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };
    console.log(courseData);
  const [loading, setloading] = useState(false);

  const notify = (mess, type) => {
    toast[type](mess);
  }; 
  const  navigate= useNavigate()
  const handelverifiedCousre = async (studentId) => {
    try {
      setloading(true);
      const response = await axios.put(
        `https://itc-541ea27f6158.herokuapp.com/api/v1/user-service/verify?studentId=${studentId}`,
        null, // Pass null as the second argument if you don't need to send any data in the body
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          },
        }
      );
      console.log(response);
      setloading(false);
      notify("تم تغيير حالة التوثيق بنجاح", "success");
      if (response.status === 200) {
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/user-service/students?page=${currentPage}&size=10&query=${searchQuery}`, 
         {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setCourseData(response?.data.data);
      }

      
  
    } catch (error) {
      setloading(false);
      notify(error.response.data.message, "error");
  
      console.error('Error deleting course subscription:', error);
      // Handle error
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true)
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/user-service/students?page=${currentPage}&size=10&query=${searchQuery}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setCourseData(response?.data.data);
      }    catch (error) {
        if (error.response.status === 405) {
          setloading(false)
          navigate('/login') ;
           notify(error.response.data.message, "success");
    
        }
        setloading(false)
    
        notify(error.response.data.message, "success");
    
        console.error('Error fetching profile data:', error);
      }
    };
  

    fetchData();

  }, [currentPage,searchQuery]);
  console.log(courseData);
  const totalPages = courseData?.totalPages;

  const handlePageChange = (page) => {
      setCurrentPage(page);
  };
  console.log(currentPage);
  return (
    <>
      <Helmet>
        <title>الطلاب المشتركين</title>
      </Helmet>
     
          <div className={`${Style.home} bg-gray-100  rounded-xl min-h-screen  `}>
          <form class="max-w-md mx-auto mb-5">   
    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input
  type="search"
  id="default-search"
  className="block w-full outline-none p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-green-500"
  placeholder="ابحث عن اسم الطالب او الايميل .."
  value={searchQuery}
  onChange={handleSearchChange}
/>    </div>
</form>
{loading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
       {courseData?.length==0?<>
       <div className='bg-white text-center w-6/12 mx-auto p-5 text-customGreen rounded-lg font-bold'>
        <h2>لا يوجد طلاب مسجلين</h2>
       </div>
       </>:<>
       <table className="Exam-table">
          <thead className="Exam-table-Head ">
            <tr className="flex flex-row w-full h-15">
              <th className="max-h-14 flage flex w-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="18"
                  viewBox="0 0 20 24"
                  fill="none"
                >
                  <path
                    d="M0 0L1.50842 0L1.50842 24H0L0 0ZM21 6.75L3.01683 13.4531L3.01683 0.046875L21 6.75ZM4.52525 11.2969L16.7104 6.75L4.52525 2.20313L4.52525 11.2969Z"
                    fill="#009539"
                  />
                </svg>
              </th>
              <th className=" Address-Exam mx-3 p-3 max-h-14 basis-5/6 ">
                <div className="flex flex-row">
                  <div className="text-right basis-1/4">اسم الطالب</div>
                  <div className="text-center basis-1/4">الايميل</div>
                  <div className="text-center basis-1/4">رقم الهاتف</div>
                  <div className="text-center basis-1/4">الدولة</div>
                </div>
              </th>
              <th className="flex-none Address-Exam-red max-h-14 pt-3 p-6 text-customGreen">موثق</th>
            </tr>
          </thead>

          <tbody>
          {courseData?.items.map((course , cousreIndex)=>{
            return <>
              {[cousreIndex+1].map((index) => (
<>
            <span className={Style.brmedium}></span>
            <span className={Style.brmedium}></span>

            <tr className="flex flex-row h-15 ">
              <th className="max-h-16  flex w-16 flage-Exam">{index}</th>
              <th className=" Address-Exam-Body mx-3 p-3 max-h-14 basis-5/6 ">
                <div className="flex flex-row">
                  <div className="text-right basis-1/4">{course?.fullName}</div>
                  <div className="text-center basis-1/4">{course?.email}</div>
                  <div className="text-center basis-1/4">{course?.phone}</div>
                  <div className="text-center basis-1/4">
                    <p>{course?.countryInAr}</p>
                  </div>
                </div>
              </th>
              <th onClick={() => {
        document.getElementById('my_modal_2').showModal();
        setStudentId( course?.id );
    }} className="cursor-pointer flex-none Address-Exam max-h-14 pt-3 p-7">
             {course?.isVerified?<>
              <svg width="29" height="29" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.9073 13.5175 6.83375 13.4076 6.78357 13.2866C6.73339 13.1657 6.70756 13.036 6.70756 12.905C6.70756 12.774 6.73339 12.6443 6.78357 12.5234C6.83375 12.4024 6.9073 12.2925 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z" fill="#0D85C9"/>
</svg>
</>:<>
<svg width="29" height="29" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.9073 13.5175 6.83375 13.4076 6.78357 13.2866C6.73339 13.1657 6.70756 13.036 6.70756 12.905C6.70756 12.774 6.73339 12.6443 6.78357 12.5234C6.83375 12.4024 6.9073 12.2925 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z" fill="#808080"/>
</svg>


</>}
<dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4">هل انت متأكد من تغيير حاله التوثيق ؟</h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
        {/* if there is a button in form, it will close the modal */}
               <button onClick={() => handelverifiedCousre( studentId ) }class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>
         
              </th>
           
            </tr>
      
          </>
          ))}
            
            </>
          })}
        
          </tbody>
        </table>
       </>}


       <nav aria-label="Page navigation example">
       {totalPages!==0?<>
            <ul className="flex items-center justify-center my-10 -space-x-px h-10 text-base">
    {currentPage==1?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage - 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Previous</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
        </svg>
      </a>
    </li></>}

    {Array.from({ length: Math.min(totalPages, 10) }, (_, index) => {
      const page = currentPage > 10 ? currentPage - 9 + index : index + 1;
      return (
        <li key={page}>
          <a
            className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage === page ? 'font-bold border-green-600 text-green-500' : 'text-gray-500'}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        </li>
      );
    })}
      {totalPages==currentPage?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage + 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Next</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
        </svg>
      </a>
    </li></>}
   
  </ul>
          </>:<>
          </>}
</nav>

      
          </>}
        </div>
     
                        
    
   
    </>
  );
}


export default NewComplaints;
