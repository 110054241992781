import { createContext, useState } from "react";


export let UserContext=createContext();

export default function UserContextProvider (props) {
    const [userToken, setuserToken] = useState(null);
    const headers=`Bearer ${userToken}`    
    return <UserContext.Provider value={{userToken, setuserToken, headers}}>
        {props.children}
    </UserContext.Provider>
    
}