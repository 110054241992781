import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './Subject_SubScription.module.css'
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup'

const SubjectSubScription = () => {
    const notify = (mess, type) => {
        toast[type](mess);
      };
    const [Loading, setLoading] = useState(false);
    let navigate =useNavigate()

    async function submitLogin(values){
      setLoading(true);
  console.log(values);
  setLoading(false);

  try {
    const response = await axios.post('https://itc-541ea27f6158.herokuapp.com/api/v1/course', values,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
    });
    notify("تم اضافه الدوره التعليمية بنجاح", "success");

    console.log(response);
    navigate('/');

  }   catch (error) {
    if (error.response.status === 405) {
      setLoading(false)
      navigate('/login') ;
       notify(error.response.data.message, "success");

    }
    setLoading(false)

    notify(error.response.data.message, "success");

    console.error('Error fetching profile data:', error);
  }
      }
      let validationSchema=Yup.object({
        title: Yup.string()
        .min(3, 'يجب أن يحتوي اسم الدورة على الأقل 3 أحرف')
        .max(100, 'يجب ألا يتجاوز اسم الدورة 100 حرفًا')
        .required('اسم الدورة مطلوب'),
           
        isFree: Yup.string()
        .required('نوع الدورة مطلوب'),
        price: Yup.string()
        .required('السعر مطلوب'),
      description: Yup.string()
      .min(3, 'يجب أن يحتوي وصف الدورة على الأقل 3 أحرف')
      .max(500, 'يجب ألا يتجاوز وصف الدورة 500 حرفًا')
        .required('وصف الدورة مطلوب'),
      })
    let formik=useFormik({
        initialValues:{
            title:'',
            description:'',
            isFree:null,
            price:'',
        },
        validationSchema
        ,
        onSubmit:submitLogin
      })
    
    return <>
    <Helmet>
          <title>أضافة دورة تعليمية</title>
          </Helmet>
<div>
  
<div className={`${Style.home} bg-gray-100 min-h-screen     rounded-tr-3xl    `}>
    
<div className="  flex items-center bg-white p-5 rounded-xl my-5">
<div className="flex items-center">
        <Link to={'/'} className="mr-2 text-green-500">قائمة الدورات التعليمية</Link>
    </div>   
    
     <div className="flex items-end pt-1">
        <span className='px-2'>
            <svg width="9" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z" fill="#009539" fillOpacity="0.7"/>
            </svg>
        </span>
    </div>
    <Link to={'/Addcourse'} className='font-bold text-green-600'>اسم الدورة التعليمية</Link>
</div>


            
<section class="bg-white rounded-xl">
  <div class=" p-4 mx-auto max-w-4xl">
      <form onSubmit={formik.handleSubmit}>
          <div class="grid gap-4 grid-cols-2">
              <div class="sm:col-span-2">
              {formik.errors.title && formik.touched.title? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.title}</span>
</div>
     </>:null}
                  <label for="title" class="block mb-2 text-sm font-medium text-green-600 ">اسم الدورة التعليمية</label>
                  <input type="text" maxLength="100" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.title} name="title" id="title" class="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg  block w-full p-2.5 outline-none" placeholder="أكتب اسم الدوره التعليمية" />
              </div>
              <div class="sm:col-span-2">
              {formik.errors.description && formik.touched.description? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.description}</span>
</div>
     </>:null}
                  <label for="description" class="block mb-2 text-sm font-medium text-green-600 ">وصف الدورة</label>
                  <textarea maxLength="500" id="description"                     style={{ whiteSpace: 'pre-wrap' }}
 onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.description} name='description' rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-green-500  outline-none" placeholder="وصف الدورة التعليمية"></textarea>
              </div>
              {formik.errors.isFree && formik.touched.isFree? <>
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
  <span className="block sm:inline">{formik.errors.isFree}</span>
</div>
     </>:null}
              <li className='w-full list-none'>
        <input  
 type="radio" id="hosting-big" onBlur={formik.handleBlur} onChange={formik.handleChange} checked={formik.values.isFree === "false"} value="false" name="isFree"  class="hidden peer"/>
        <label for="hosting-big" class="inline-flex border-2 items-center justify-between w-full p-5 text-gray-500 bg-white  border-gray-200 rounded-lg cursor-pointer  peer-checked:border-green-600 peer-checked:text-green-600">
            <div class="block">
                <div class="w-full text-lg font-semibold">مدفوع</div>
            </div>
            <i class="fa-solid text-green-600 fa-xl fa-check"></i>
        </label>
    </li>
    <li className='w-full list-none	'>
        <input   type="radio" onBlur={formik.handleBlur} onChange={formik.handleChange}  checked={formik.values.isFree === "true"} value="true" id="hosting-small" name="isFree"  class="hidden peer" required />
        <label for="hosting-small" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer  peer-checked:border-green-600 peer-checked:text-green-600">                           
            <div class="block">
                <div class="w-full text-lg font-semibold">مجاني</div>
                </div>
        </label>
    </li>
    {formik.values.isFree !== "true" ? (
  <div className="sm:col-span-2">
    {formik.errors.price && formik.touched.price ? (
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
        <span className="block sm:inline">{formik.errors.price}</span>
      </div>
    ) : null}
    <label htmlFor="title" className="block mb-2 text-sm font-medium text-green-600">
      سعر الدورة التعليمية
    </label>
    <input
      type="text"
      maxLength="100"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.price}
      name="price"
      id="price"
      className="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
      placeholder=" أكتب سعر الدوره التعليمية مع توضيح العمله"
    />
  </div>
) :  <div className="sm:col-span-2">
    {formik.errors.price && formik.touched.price ? (
      <div className="bg-red-100 border mb-2 border-red-400 text-red-700 px-3 py-2 rounded relative" role="alert">
        <span className="block sm:inline">{formik.errors.price}</span>
      </div>
    ) : null}
    <label htmlFor="title" className="block mb-2 text-sm font-medium text-green-600">
      سعر الدورة التعليمية
    </label>
    <input
      type="text"
      maxLength="100"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value="مجانا"
      name="price"
      id="price"
      disabled
      className="bg-gray-50 border-2 border-green-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none"
      placeholder=" أكتب سعر الدوره التعليمية مع توضيح العمله"
    />
  </div>}



  
          </div>
          {Loading?<>
            <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
     <i className='fas fa-spinner fa-spin'></i>
         </button>
  </>:<>
  <button type="submit" class="flex bg-customGreen w-6/12 mx-auto btn items-center px-5 py-2.5 mt-4 sm:mt-6 text-lg font-medium text-center text-white bg-primary-700 rounded-lg outline-none hover:bg-green-700">
              أضافه دورة تعليمية
          </button>
  </>}
        
      </form>
  </div>
</section>
  
  
                    
  
  
            </div>
</div>
         
    </>
}

export default SubjectSubScription;
