import React, { useState } from "react";
import Style from "./Question.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Question.css";
import axios from "axios";
import { toast } from "react-toastify";
export const Question = () => {
  const {quizId,vedioId}= useParams()

  const [selectedOption, setSelectedOption] = useState("");
  const [questionValues, setQuestionValues] = useState({
    questionTitle: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    correctAnswer: "",
    image: "",
  });
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
    const selectedAnswer = event.target.dataset.answer;
    setQuestionValues({ ...questionValues, correctAnswer: selectedAnswer });
  };
  const handleAnswerChange = (event, fieldName) => {
    setQuestionValues({ ...questionValues, [fieldName]: event.target.value });
  };

  const handleImageChange = (file) => {
    setQuestionValues({ ...questionValues, image: file });
  };

  const handleSvgClick = () => {
    // Trigger click event on input element when svgimge is clicked
    const inputElement = document.getElementById("fileInput");
    inputElement.click();
  };

  const handleQuestionTitleChange = (event) => {
    setQuestionValues({ ...questionValues, questionTitle: event.target.value });
  };
  const handleDeleteImage = () => {
    setQuestionValues({ ...questionValues, image: null });
  };
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const [Loading, setLoading] = useState(false);
  let navigate =useNavigate()


 
  const handelValues = async() => {
    console.log(questionValues);
    try {
      setLoading(true)
        const formData = new FormData();
        formData.append('questionTitle', questionValues.questionTitle);
        formData.append('answer1', questionValues.answer1);
        formData.append('answer2', questionValues.answer2);
        formData.append('answer3', questionValues.answer3);
        formData.append('answer4', questionValues.answer4);
        formData.append('correctAnswer', questionValues.correctAnswer);
        if (questionValues.image) {
          formData.append("image", questionValues.image);
        }

        const response = await axios.post(`https://itc-541ea27f6158.herokuapp.com/api/v1/quizzes/${quizId}/questions`, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        setLoading(false)
        notify("تم أضافه السؤال بنجاح", "success");
        console.log(response);
        navigate(`/${vedioId}/ExamDetails`);

    }    catch (error) {
      if (error.response.status === 405) {
        setLoading(false)
        navigate('/login') ;
         notify(error.response.data.message, "success");
  
      }
      setLoading(false)
  
      notify(error.response.data.message, "success");
  
      console.error('Error fetching profile data:', error);
    }
};
  return (
    <>
      <div
        className={`${Style.home} bg-gray-100 min-h-screen     rounded-tr-3xl    `}
      >
        <div className="  flex items-center bg-white p-5 rounded-xl my-5">
          <div className="flex items-center">
            <Link to={"/"} className="mr-2 text-green-500">
            الدوره التعليمية id
            </Link>
          </div>
          <div className="flex items-end pt-1">
            <span className="px-2">
              <svg
                width="9"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z"
                  fill="#009539"
                  fillOpacity="0.7"
                />
              </svg>
            </span>
          </div>
          <Link to={"/Subject"} className="text-green-500">
            الموضوع id
          </Link>
          <div className="flex items-end pt-1">
            <span className="px-2">
              <svg
                width="9"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z"
                  fill="#009539"
                  fillOpacity="0.7"
                />
              </svg>
            </span>
          </div>
          <Link to={"/vedios"} className="text-green-600">
            الفيديوهات
          </Link>

          <div className="flex items-end pt-1">
            <span className="px-2">
              <svg
                width="9"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.85398 0.319999V2.3L2.67398 5.36L8.85398 8.06V10.02L0.173984 6.04V4.84L8.85398 0.319999Z"
                  fill="#009539"
                  fillOpacity="0.7"
                />
              </svg>
            </span>
          </div>
          <Link to={"/"} className="font-bold text-green-600">
            اضافة اختبار{" "}
          </Link>
        </div>
        <div className="Contant-Test">
          <div className="choes-Question">
            <p className="TextChoes">
              {" "}
              قم باضافة السؤال والاختبارات ثم قم بتحديد الاجابة الصحيحة{" "}
            </p>
            <div className="input-Question rtl">
              <input
                type="text"
                className="enter-question"
                placeholder="ادخل السؤال"
                // eslint-disable-next-line eqeqeq
                onChange={handleQuestionTitleChange}
              />
              <div className="add-pictuer">
                <div className="inputImage">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    className="i"
                    onChange={(e) => handleImageChange(e.target.files[0])}
                    style={{ display: "none" }} // Hide the input element
                  />
                </div>
                <div className="svgimge" onClick={handleSvgClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={25}
                    height={25}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="green"
                      d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                    />
                  </svg>
                </div>
              </div>
              <div className="Delet-Question" onClick={() => handleDeleteImage()}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 448 512"
  >
    <path
      fill="red"
      d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
    />
  </svg>
</div>
            </div>
                 <div className="answers grid grid-cols-3">
   
              <ul className="answersContiner h-15 col-span-2 ">
                <li className="w-full border-b border-gray-200 ">
                  <div className="flex items-center ps-3">
                    <input
                      id="list-radio-license1"
                      type="radio"
                      name="list-radio"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                      checked={selectedOption === "list-radio-license1"}
                      onChange={handleOptionChange}
                      data-answer={questionValues.answer1} // Set the answer value as data attribute
                      data-field="answer1" // Set the field name as data attribute
                    />
                    <label
                      htmlFor="list-radio-license1"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900 "
                    >
                      <input
                        type="text"
                        className="answerInputText"
                        placeholder="الخيار الاول"
                        value={questionValues.answer1} // Bind value to questionValues
                        onChange={(e) => handleAnswerChange(e, "answer1")}
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 ">
                  <div className="flex items-center ps-3">
                    <input
                      id="list-radio-license2"
                      type="radio"
                      name="list-radio"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                      checked={selectedOption === "list-radio-license2"}
                      onChange={handleOptionChange}
                      data-answer={questionValues.answer2} // Set the answer value as data attribute
                      data-field="answer2" // Set the field name as data attribute
                    />
                    <label
                      htmlFor="list-radio-license2"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900 "
                    >
                      <input
                        type="text"
                        className="answerInputText"
                        placeholder="الخيار الثاني"
                        value={questionValues.answer2} // Bind value to questionValues
                        onChange={(e) => handleAnswerChange(e, "answer2")}
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 ">
                  <div className="flex items-center ps-3">
                    <input
                      id="list-radio-license3"
                      type="radio"
                      name="list-radio"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                      checked={selectedOption === "list-radio-license3"}
                      onChange={handleOptionChange}
                      data-answer={questionValues.answer3} // Set the answer value as data attribute
                      data-field="answer3" // Set the field name as data attribute
                    />
                    <label
                      htmlFor="list-radio-license3"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900 "
                    >
                      <input
                        type="text"
                        className="answerInputText"
                        placeholder="الخيار الثالث"
                        value={questionValues.answer3} // Bind value to questionValues
                        onChange={(e) => handleAnswerChange(e, "answer3")}
                      />
                    </label>
                  </div>
                </li>
                <li className="w-full border-b border-gray-200 ">
                  <div className="flex items-center ps-3">
                    <input
                      id="list-radio-license4"
                      type="radio"
                      name="list-radio"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                      checked={selectedOption === "list-radio-license4"}
                      onChange={handleOptionChange}
                      data-answer={questionValues.answer4} // Set the answer value as data attribute
                      data-field="answer4" // Set the field name as data attribute
                    />
                    <label
                      htmlFor="list-radio-license4"
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900 "
                    >
                      <input
                        type="text"
                        className="answerInputText"
                        placeholder="الخيار الرابع"
                        value={questionValues.answer4} // Bind value to questionValues
                        onChange={(e) => handleAnswerChange(e, "answer4")}
                      />
                    </label>
                  </div>
                </li>
              </ul>
              {questionValues.image && (
                
    <img
      src={URL.createObjectURL(questionValues.image)}
      alt="Uploaded Image"
      
      className="previewImage w-full"
    />

  )}  
            </div>
            <div className="AddButton">
            {Loading ? (
                <button className="Add" type="button">
                  <i className='fas fa-spinner fa-spin'></i>
              </button>
              ) : (
                <button className="Add" onClick={handelValues}>
                اضافة
              </button>
              )}
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
