  import React, { useEffect, useState } from 'react';
  import Style from './Profile.module.css';
  import { useForm } from 'react-hook-form'; // Importing useForm from react-hook-form instead of useFormik
  import { Helmet } from 'react-helmet';
  import './profile.css';
  import axios from 'axios';
  import { Link, useNavigate } from 'react-router-dom';
  import * as Yup from 'yup';
  import { toast } from 'react-toastify';

  export const Profile = () => {
    const notify = (mess, type) => {
      toast[type](mess);
    };
    const [Loading, setLoading] = useState(false);
    let navigate =useNavigate()

  const [editName, setEditName] = useState({});
    const [editNumber, setEditNumber] = useState({});
    const [editCountry, setEditCountery] = useState({});
    const [countries, setCountries] = useState([]);
    const [currentData, setCurrentData] = useState({});
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://itc-541ea27f6158.herokuapp.com/api/v1/profile', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
          });
          setCurrentData(response.data.data);
        }    catch (error) {
          if (error.response.status === 405) {
            navigate('/login') ;
             notify(error.response.data.message, "success");
      
          }
      
          notify(error.response.data.message, "success");
      
          console.error('Error fetching profile data:', error);
        }
      };

      fetchData();
    }, []);
    async function submitupdate(values){
      // setLoading(true);
  console.log(values);  
    try {
      const response = await axios.put('https://itc-541ea27f6158.herokuapp.com/api/v1/profile', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      console.log(response);
      if (response.status==200) {
        notify("تم تعديل البيانات  بنجاح", "success");
        localStorage.setItem('userToken',response.data.data.token)
        localStorage.setItem('user',response.data.data.fullName)
        // setLoading(false);
        navigate('/');
      }
    } catch (error) {
      notify(error.response.data.message, "error");
      // setLoading(false);
    }
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object({
    fullName: Yup.string().min(2, 'اقل عدد حروف 2').max(40 ,"اقصي عدد حروف 40 حرف").required("الاسم مطلوب"), 
    phone: Yup.string().matches(phoneRegExp, 'رقم الهاتف خطأ').required('رقم الهاتف مطلوب'),
    countryId: Yup.string().required('الدوله مطلوبه')
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      fullName: currentData?.fullName || '' , 
      phone: currentData?.phone || '',
      countryId: currentData?.countryId || ''
    },
    validationSchema
  });
  useEffect(() => {
    if (currentData) {
      setValue('fullName', currentData?.fullName);
      setValue('phone', currentData?.phone);
      setValue('email', currentData?.email);
      setValue('countryId', currentData?.countryId);
    }
  }, [currentData, setValue]);
  console.log(currentData);
    useEffect(() => {
      const fetchCountries = async () => {
        try {
          const response = await axios.get('https://itc-541ea27f6158.herokuapp.com/api/v1/countries');
          setCountries(response.data.data);
        } catch (error) {
          console.error('Error fetching countries data:', error);
        }
      };

      fetchCountries();
    }, []);




    return (

      <>
      
      <>
        <Helmet>
          <title>
        البروفايل</title>
        </Helmet>
        <div className={`${Style.home} bg-gray-100 rounded-xl w-full min-h-screen  `}>
          <div className="flex items-center max-sm:justify-center bg-white p-5 rounded-xl my-5">
            <p to={'/'} className="mr-2 text-maingreen font-semibold">الملف الشخصي</p>
          </div>  
      {/* <input type="text" onBlur={formik.handleBlur} name='fullName' onChange={formik.handleChange} value={formik.values.fullName} /> */}


  <div className='continerPrpfile'>

  <form  onSubmit={handleSubmit(submitupdate)}>



      <div className="relative input-Test  ">
    <input
    name='fullName'
      type="text"
      id="outlined_success"
      {...register("fullName")}
      aria-describedby="outlined_success_help"
      readOnly={editName?true :false}
      className="rtl  Address-input  font-bold p-4 border border-green-600  text-customGreen  rounded-lg"
    />
    <label
      htmlFor="outlined_success"
      className="rtl TextLable absolute text-sm text-green-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
  الاسم 
  </label>
  {editName ?
  
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={20}
  height={20}
  className='editIconProfile'
  onClick={() => setEditName(!editName)} 
  viewBox="0 0 512 512">
  <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>

  :

  <svg xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  className='editIconProfile'
  onClick={() => setEditName(!editName)} 
  
  viewBox="0 0 448 512" >
    
  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>


  }

  </div>


  <div className="relative input-Test ">
    <input
    name='phone'
      type="text"
      id="outlined_success"
      {...register("phone")}

      aria-describedby="outlined_success_help"
      readOnly={editNumber?true :false}
      className="rtl  Address-input  font-bold p-4 border border-green-600 text-customGreen   rounded-lg"
    />
    <label
      htmlFor="outlined_success"
      className="rtl TextLable absolute text-sm text-green-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
  الهاتف
  </label>
  {editNumber ?
  
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={20}
  height={20}
  className='editIconProfile'
  onClick={() => setEditNumber(!editNumber)} 
  viewBox="0 0 512 512">
  <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>

  :

  <svg xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  className='editIconProfile'
  onClick={() => setEditNumber(!editNumber)} 
  
  viewBox="0 0 448 512">
  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>


  }

  </div>

  <div className="relative input-Test ">
    <input
      type="text"
      id="outlined_success"
      aria-describedby="outlined_success_help"
      readOnly
      {...register("email")}

      className="rtl  Address-input  font-bold text-customGreen p-4 border border-green-600   rounded-lg"
    />
    <label
      htmlFor="outlined_success"
      className="rtl TextLable  absolute text-sm text-green-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
  البريد الألكتروني
  </label>
  

  </div>


  <div className="relative input-Test ">

    <select 
          {...register("countryId")}
      name='countryId' 
    
      className="placeholder-green-600 rtl  text-customGreen Address-input placeholder:opacity-75 text-base h-full w-40 rounded-md border border-customGreen max-sm:p-2   p-3 text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200"
    >
    
      {countries.map(country => (
        <option key={country.id} value={country.id}>{country.arabicName} ({country.englishName})</option>
      ))}
    </select>
    <label
      htmlFor="outlined_success"
      className="rtl TextLable absolute text-sm text-green-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
  الدولة
  </label>

  </div>
  {Loading?<>
    <div className="Buttons">
  <button type='button'  className='btn  hover:bg-green-900 save-Edit'> 
  <i className='fas fa-spinner fa-spin'></i>
  </button>
  </div>
  </>:<>
  <div className="Buttons">
  <button type='submit'  className='btn  hover:bg-green-900 save-Edit'> حفظ التعديلات</button>
  </div>
  </>}

  </form>

  </div>

        </div>
      </>

      </>
    )
  }
