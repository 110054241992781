import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Style from './SubScripe.module.css'
import "./Exam.css";
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
const SubScripe = () => {
  const notify = (mess, type) => {
    toast[type](mess);
  };
  const [searchQuery, setSearchQuery] = useState('');
const handleSearchChange = (event) => {
  setSearchQuery(event.target.value);
};
  const [courseData, setCourseData] = useState(null);
  const [loading, setloading] = useState(false);
  const [subscribedCourseId, setsubscribedCourseId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const handelAprroveCousre = async (courseIndex) => {
    try {
      setloading(true);
      const response = await axios.post(
        `https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/approve/${courseIndex.studentId}/${courseIndex.subscribedCourseId}`,
        null, // You don't need to send any data in the request body
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        }
      );
      setloading(false);
      
      notify("تم تأكيد الطلب بنجاح", "success");

      // If the approve request is successful, update the courseData state by removing the corresponding course
      if (response.status === 200) {
        const response = await axios.get(`https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/subscribers?subscriptionStatus=PENDING&page=${currentPage}&size=10`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setCourseData(response?.data.data);
      }

    } catch (error) {
      setloading(false);
      notify(error.response.data.message, "error");

      console.error('Error approving subscription:', error);
      // Handle error
    }
  };
  const handeldeleteCousre = async (courseIndex) => {
    try {
      setloading(true);
      const response = await axios.delete(
        `https://itc-541ea27f6158.herokuapp.com/api/v1/subscription`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          },
          data: {
            studentId: courseIndex?.studentId,
            courseId: courseIndex?.subscribedCourseId
          }
        }
      );
      setloading(false);
      notify("تم رفض طلب الاشتراك بالدوره التعليمية بنجاح", "success");
      if (response.status === 200) {
        const response = await axios.get(
          `https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/subscribers?subscriptionStatus=PENDING&page=${currentPage}&size=10&query=${searchQuery}`,
         {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        setloading(false)
        setCourseData(response?.data.data);
      }

      
  
    } catch (error) {
      setloading(false);
      notify(error.response.data.message, "error");
  
      console.error('Error deleting course subscription:', error);
      // Handle error
    }
  };
  const  navigate= useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [currentGroup, setCurrentGroup] = useState(0);

  const itemsPerPage = 5; // Number of pages to show in pagination at a time



  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        const response = await axios.get(
          `https://itc-541ea27f6158.herokuapp.com/api/v1/subscription/subscribers?subscriptionStatus=PENDING&page=${currentPage}&size=10&query=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
          }
        );
        setloading(false);
        setCourseData(response?.data.data);
      } catch (error) {
        if (error.response.status === 405) {
          setloading(false);
          navigate('/login');
          notify(error.response.data.message, "success");
        }
        setloading(false);
        notify(error.response.data.message, "success");
        console.error('Error fetching profile data:', error);
      }
    };
  
    fetchData();
  }, [currentPage, searchQuery]);
  const totalPages = courseData?.totalPages;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextGroup = () => {
    setCurrentGroup(currentGroup + 1);
  };

  const handlePrevGroup = () => {
    setCurrentGroup(currentGroup - 1);
  };

  const renderPagination = () => {
    const startPage = currentGroup * itemsPerPage + 1;
    const endPage = Math.min(startPage + itemsPerPage - 1, totalPages);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <a
            className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage === i ? 'font-bold border-green-600 text-green-500' : 'text-gray-500'}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </a>
        </li>
      );
    }
  }
  return (
    <>
      <Helmet>
        <title>طلبات الاشتراك</title>
      </Helmet>
     
          <div className={`${Style.home} bg-gray-100  rounded-xl min-h-screen  `}>
            
<form class="max-w-md mx-auto mb-5">   
    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input
  type="search"
  id="default-search"
  className="block w-full outline-none p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-green-500"
  placeholder="ابحث عن اسم الطالب او الايميل .."
  value={searchQuery}
  onChange={handleSearchChange}
/>    </div>
</form>

          {loading?<>
                <div className={`${Style.home2} w-full flex justify-center`}>
                <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

</div>
          </>:<>
       <table className="Exam-table">
          <thead className="Exam-table-Head">
            <tr className="flex flex-row  h-15">
              <th className="max-h-14 flage flex w-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="18"
                  viewBox="0 0 20 24"
                  fill="none"
                >
                  <path
                    d="M0 0L1.50842 0L1.50842 24H0L0 0ZM21 6.75L3.01683 13.4531L3.01683 0.046875L21 6.75ZM4.52525 11.2969L16.7104 6.75L4.52525 2.20313L4.52525 11.2969Z"
                    fill="#009539"
                  />
                </svg>
              </th>
              <th className=" Address-Exam mx-3 p-3 max-h-14 basis-5/6 ">
                <div className="flex flex-row">
                  <div className="text-right basis-1/4">اسم الطالب</div>
                  <div className="text-center basis-1/4">الايميل</div>
                  <div className="text-center basis-1/4">عنوان الدوره التعليمية</div>
                  <div className="text-center basis-1/4">رقم الهاتف</div>
                </div>
              </th>
              <th className="me-2 flex-none Address-Exam max-h-14 pt-3 p-5">قبول</th>
              <th className=" flex-none Address-Exam-red max-h-14 pt-3 p-5 text-red-600">رفض</th>
            </tr>
          </thead>

          <tbody>
          {courseData?.items?.map((course , cousreIndex)=>{
            return <>
              {[cousreIndex+1].map((index) => (
<>
            <span className={Style.brmedium}></span>
            <span className={Style.brmedium}></span>

            <tr className="flex flex-row h-15 ">
              <th className="max-h-16  flex w-16 flage-Exam">{index}</th>
              <th className=" Address-Exam-Body mx-3 p-3 max-h-14 basis-5/6 ">
                <div className="flex flex-row">
                  <div className="text-right basis-1/4">{course?.studentName}</div>
                  <div className="text-center basis-1/4">{course?.studentEmail}</div>
                  <div className="text-center basis-1/4">{course?.subscribedCourseTitle}</div>
                  <div className="text-center basis-1/4">{course?.studentPhone}</div>

                </div>
              </th>
              <th onClick={() => {
        document.getElementById('my_modal_2').showModal();
        setsubscribedCourseId(course?.subscribedCourseId);
        setStudentId( course?.studentId );
    }} className="me-2 cursor-pointer flex-none Address-Exam max-h-14 pt-3 p-5">
                
              <svg xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              className="checkIcon"
              viewBox="0 0 448 512">
              <path
              fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></th>
              <th onClick={() => {
        document.getElementById('my_modal_3').showModal();
        setsubscribedCourseId(course?.subscribedCourseId);
        setStudentId( course?.studentId );
    }} className="cursor-pointer flex-none Address-Exam max-h-14 pt-3 p-5">
                              <i class="text-red-600 fa-solid fa-circle-xmark pt-4 p-2.5 fa-xl"></i>
                              </th>
            </tr>
            <dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4">هل انت متأكد من تأكيد الطلب؟</h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
               <button onClick={() => handelAprroveCousre({ subscribedCourseId, studentId })} class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>
<dialog id="my_modal_3" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-4">هل انت متأكد من رفض الطلب؟</h3>
 
  <div className="modal-action">
      <form method="dialog" className='mx-auto'>
               <button onClick={() => handeldeleteCousre({ subscribedCourseId, studentId })} class="py-2.5 px-5  text-sm font-medium text-white  bg-customGreen rounded-lg border border-gray-200   ">
                   تأكيد 
                    </button>
                <button class="text-white ms-3 bg-red-600 hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    الغاء 
                </button>
              
              </form>
    </div>
    </div>

</dialog>
          </>
          ))}
            
            </>
          })}
        
          </tbody>
        </table>
       
        <nav aria-label="Page navigation example">
          {totalPages!==0?<>
            <ul className="flex items-center justify-center my-10 -space-x-px h-10 text-base">
    {currentPage==1?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage - 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Previous</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
        </svg>
      </a>
    </li></>}

    {Array.from({ length: Math.min(totalPages, 10) }, (_, index) => {
      const page = currentPage > 10 ? currentPage - 9 + index : index + 1;
      return (
        <li key={page}>
          <a
            className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${currentPage === page ? 'font-bold border-green-600 text-green-500' : 'text-gray-500'}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        </li>
      );
    })}
      {totalPages==currentPage?<>
    </>:<>
    <li onClick={() => handlePageChange(currentPage + 1)}>
      <a href="#" className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span className="sr-only">Next</span>
        <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
        </svg>
      </a>
    </li></>}
   
  </ul>
          </>:<>
          </>}

</nav>
      
          </>}
        </div>
                        
    
   
    </>
  );
}

export default SubScripe;
